import { useQuery } from "react-query";
import axios from "axios";
import { GROUP_TOURS } from "../endpoints";
import { IGroupTours } from "core/types/groupTours";

export const useGetGroupTours = () => {
  const fetchGroupTours: () => Promise<IGroupTours[]> = async () => {
    const res = await axios.get(`${window.BASE_API_URL}/${GROUP_TOURS}`);
    return res.data.tours;
  };

  return useQuery(`tour-groups`, fetchGroupTours, {
    enabled: true,
    refetchOnWindowFocus: false,
  });
};

import React, { SetStateAction } from "react";
import { Wrapper } from "src/components/molecules/GiftPayment/components/PaymentTypeComponent/styledComponents";
import { RadioComponent } from "src/components/molecules/RecapPayment/components/RadioComponent";
import { RadioType } from "src/core/types/radioTypes";
import { paymentGiftTypes } from "src/core/lists/paymentTypes";

export const PaymentTypeComponent = ({
  radioState,
  setRadioState,
}: {
  radioState: RadioType;
  setRadioState: React.Dispatch<SetStateAction<RadioType>>;
}) => {
  return (
    <Wrapper>
      <RadioComponent
        options={paymentGiftTypes}
        radioState={radioState}
        setRadioState={setRadioState}
      />
    </Wrapper>
  );
};

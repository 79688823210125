import { CheckOutGiftStep } from "src/core/types";

export const setNextGifStep = ({
  panels,
  currentKey,
}: {
  panels: CheckOutGiftStep[];
  currentKey: number;
}) => {
  const newPanelsState = panels.map((panel: CheckOutGiftStep) => {
    if (Number(panel.key) <= currentKey) {
      return { ...panel, isEnabled: false };
    }
    if (panel.key === String(currentKey + 1)) {
      return { ...panel, isEnabled: true };
    }
    return panel;
  });
  return { newPanels: newPanelsState };
};

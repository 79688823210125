import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 697px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 20px;
  color: #062762;
`;

export const NextButton = styled.div`
  width: 100%;
  max-width: 333px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
  padding: 18px 16px 18px 24px;
  border-radius: 35px;
  background: #e31240;
  color: #ffffff;
  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
`;

import React from "react";
import {
  ConfirmButton,
  Container,
  Title,
  TitleWrapper,
  Wrapper,
} from "src/components/molecules/GiftReservationConfirm/styledComponents";
import { CheckOutGiftStep, CheckOutStep } from "core/types";
import { SubPageHeader } from "src/components/molecules/SubPageHeader";
import { setGiftStep } from "src/core/utils/setGiftStep";
import { Receipt } from "src/components/molecules/GiftReservationConfirm/components/Receipt";
import { TGiftCardTier } from "src/core/types/giftTiersTypes";
import { useTranslation } from "react-i18next";
import checkMarkButton from "../../../assets/checkmark.svg";
import { useNavigate } from "react-router-dom";
import { TICKET_TYPE_ROUTE } from "src/core/routing";
import checkIcon from "../../../assets/check (1).svg";

export const GiftReservationConfirm = ({
  step,
  panels,
  updateCheckOutState,
  selectedCard,
  cardsAmount,
}: {
  step: CheckOutStep;
  panels: CheckOutGiftStep[];
  selectedCard: TGiftCardTier;
  cardsAmount: number;
  updateCheckOutState: (newPanelsState: CheckOutGiftStep[]) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToStepBefore = () => {
    const { newPanels } = setGiftStep({ panels: panels, currentKey: Number(step.key) - 1 });
    updateCheckOutState(newPanels);
  };
  return step.isEnabled ? (
    <Container>
      <SubPageHeader navigateFunc={goToStepBefore} />
      <Wrapper>
        <TitleWrapper>
          <img src={checkIcon} alt="checkIcon" />
          <Title>{t("confirmation")}</Title>
        </TitleWrapper>
        <Receipt
          selectedCard={selectedCard}
          amount={cardsAmount}
          total={selectedCard.monetary_value * cardsAmount}
        />
        <ConfirmButton isActive={true} onClick={() => navigate(TICKET_TYPE_ROUTE)}>
          {t("finish")}
          <img src={checkMarkButton} alt="checkMark" />
        </ConfirmButton>
      </Wrapper>
    </Container>
  ) : (
    <div></div>
  );
};

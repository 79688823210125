import React from "react";
import {
  Container,
  NextButton,
  Title,
  Wrapper,
} from "src/components/molecules/TicketSelector/styledComponents";
import { TicketSelectComponent } from "src/components/pages/PageGifts/components/TicketSelectComponent";
import { TicketsCounterComponent } from "src/components/pages/PageGifts/components/TicketsCounterComponent";
import { setNextGifStep } from "src/core/utils/setNextGifStep";
import { useTranslation } from "react-i18next";
import { TGiftCardTier } from "src/core/types/giftTiersTypes";
import { Loc } from "src/core/types/loc";
import { CheckOutGiftStep, CheckOutStep } from "core/types";

import arrowRight from "../../../assets/arrow_right.svg";
import { SubPageHeader } from "src/components/molecules/SubPageHeader";
import { useNavigate } from "react-router-dom";
import { TICKET_TYPE_ROUTE } from "src/core/routing";
import Footer from "src/components/molecules/Footer";

export const TicketSelector = ({
  cards,
  loc,
  selectCard,
  selectedCard,
  cardsAmount,
  handleIncrement,
  handleDecrement,
  panels,
  step,
  updateCheckOutState,
}: {
  cards: TGiftCardTier[];
  loc: Loc;
  selectCard: (card: TGiftCardTier) => void;
  selectedCard: TGiftCardTier;
  cardsAmount: number;
  handleIncrement: () => void;
  handleDecrement: () => void;
  panels: CheckOutGiftStep[];
  step: CheckOutStep;
  updateCheckOutState: (newPanelsState: CheckOutGiftStep[]) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToNextStep = () => {
    const { newPanels } = setNextGifStep({
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newPanels);
  };

  const goToStepBefore = () => {
    navigate(TICKET_TYPE_ROUTE);
  };

  return step.isEnabled ? (
    <Container>
      <Wrapper>
        <SubPageHeader navigateFunc={goToStepBefore} />
        <Title>{t("tier_to_church")}</Title>
        <TicketSelectComponent
          loc={loc}
          cards={cards}
          onCardSelect={selectCard}
          selectedCard={selectedCard}
        />
        <TicketsCounterComponent
          amount={cardsAmount}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
        />
        <NextButton onClick={goToNextStep}>
          {t("recap_and_payment")}
          <img src={arrowRight} alt="arrowRight" />
        </NextButton>
      </Wrapper>
      <Footer />
    </Container>
  ) : (
    <div></div>
  );
};

import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo_jakub_neg.svg";
import { useTranslation } from "react-i18next";
import phoneIcon from "../../../assets/phone-call.svg";
import mailIcon from "../../../assets/mail.svg";
import styles from "./styles.module.css";
import background from "../../../assets/foto-pozadi2@2x.png";
import betrianLogo from "../../../assets/partnerIcons/betrianLogo_white.svg";

const Footer = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <Box className={styles.box}>
      <Box className={styles.imageContainer}>
        <img src={background} className={styles.image} style={{ backgroundRepeat: "repeat-x" }} />
      </Box>
      <Box className={styles.overlayPic}></Box>

      <Box className={styles.overlay}>
        <div className={styles.boxHeader}>
          <div className={styles.boxHeaderLogo}>
            <img src={logo} className={styles.logo} />
            {isMobile && (
              <Box className={styles.gridItem}>
                <Box className={styles.logo_wrapper}>
                  <span className={styles.logo_text}>{t("technology_partner")}</span>
                  <img src={betrianLogo} alt="betrianLogo" />
                </Box>
                <Typography variant="body2">{[t("rights_reserved")]}</Typography>
              </Box>
            )}
          </div>
          <div className={styles.phoneEmail}>
            <div className={styles.icons} style={{ marginRight: "50px" }}>
              <img src={phoneIcon} style={{ marginRight: "15px" }} />
              <Typography variant="h2">{t("phone")}</Typography>
            </div>
            <div className={styles.icons} style={{ marginRight: "50px" }}>
              <img src={mailIcon} style={{ marginRight: "15px" }} />
              <Typography variant="h2"> {t("email")}</Typography>
            </div>
          </div>
        </div>
        {!isMobile && <Box className={styles.horizontal_line} />}
        {!isMobile && (
          <Box className={styles.gridItem}>
            <Box className={styles.logo_wrapper}>
              <span className={styles.logo_text}>{t("technology_partner")}</span>
              <img src={betrianLogo} alt="betrianLogo" />
            </Box>
            <Typography variant="body2">{[t("rights_reserved")]}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Footer;

import * as React from "react";
import { createContext, useContext, useState } from "react";
import { RadioType } from "src/core/types/radioTypes";

export type GroupContextType = {
  id: number;
  name: string;
  visitor_amount: number;
  price: number;
  payment_method: RadioType;
};

export const defaultFormValues: GroupContextType = {
  id: 0,
  name: "",
  visitor_amount: 0,
  price: 0,
  payment_method: "cash",
};

type GroupContextTypes = {
  formValues: GroupContextType;
  setFormValues: React.Dispatch<React.SetStateAction<GroupContextType>>;
};

export const GroupValuesContext = createContext<GroupContextTypes>({} as GroupContextTypes);

export const useGroupValuesContext = () => useContext(GroupValuesContext);

export const GroupValuesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [formValues, setFormValues] = useState<GroupContextType>(defaultFormValues);

  return (
    <GroupValuesContext.Provider value={{ formValues, setFormValues }}>
      {children}
    </GroupValuesContext.Provider>
  );
};

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 18px 24px;
  height: 56px;
  gap: 5px;
  border: 1px solid #b9b9b9;
  background: #ffffff;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 100%;
  border-left: 1px dashed #b9b9b9;
`;

export const CountWrapper = styled.div`
  width: 100%;
  min-width: 310px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const CounterText = styled.div`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const Count = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #e31340;
`;

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const PriceText = styled.div`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const Price = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

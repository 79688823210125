import React from "react";
import {
  Button,
  Counter,
  Wrapper,
} from "src/components/pages/PageGifts/components/TicketsCounterComponent/styledComponents";
import minusIcon from "../../../../../assets/minus-red.svg";
import plusIcon from "../../../../../assets/plus_color.svg";

export const TicketsCounterComponent = ({
  amount,
  onIncrement,
  onDecrement,
}: {
  amount: number;
  onIncrement: () => void;
  onDecrement: () => void;
}) => {
  return (
    <Wrapper>
      <Button onClick={onDecrement}>
        <img src={minusIcon} alt="minusIcon" />
      </Button>
      <Counter>{amount}</Counter>
      <Button onClick={onIncrement}>
        <img src={plusIcon} alt="plusIcon" />
      </Button>
    </Wrapper>
  );
};

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const PanelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;
`;

export const ItemText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  color: #000000;
`;

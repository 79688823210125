import styled from "styled-components";

interface IWrapper {
  isActive: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  width: 100%;
  max-width: 159px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  border-radius: 5px;
  border: ${(props) => `1px solid ${props.isActive ? "#e31340" : "#b9b9b9"}`};
  cursor: pointer;
`;

export const TicketDescription = styled.div`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const TicketPrice = styled.div`
  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 80px;
  padding: 24px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonBack = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const Text = styled.span`
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

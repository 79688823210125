import styled from "styled-components";

interface IPaymentInfo {
  isPayed: boolean;
}

export const Container = styled.div`
  width: 100vw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Wrapper = styled.div`
  max-width: 1360px;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 48px;
  background: #f8f8f8;
  border-radius: 10px;

  @media (max-width: 768px) {
    margin: 220px 8px 100px 8px;
    padding: 16px 8px;
    width: unset;
  }

  @media (max-width: 801px) {
    width: 100%;
    max-width: 738px;
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  gap: 20px;

  @media (max-width: 801px) {
    width: 100%;
    max-width: 738px;
  }
`;

export const InnerTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
  gap: 15px;
  margin-bottom: 50px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
`;

export const PaymentInfo = styled.div<IPaymentInfo>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${(props) => (props.isPayed ? "#29CC6A" : "red")};
`;

export const RadioWrapper = styled.div`
  width: 100%;
  max-width: 690px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  margin: 20px auto 0 auto;
  border: 1px solid #b9b9b9;
  padding: 18px 34px;
  background: #ffffff;

  @media (min-width: 801px) {
    max-width: 100%;
  }
`;

export const Price = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  font-family: Montserrat, serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 50.83px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #e31340;
`;

export const TextFieldWrapper = styled.div`
  width: 100%;
  min-width: 300px;
  height: 66px;
`;

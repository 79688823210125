import React, { SetStateAction, useEffect } from "react";
import { GiftPurchase, TGiftCardTier } from "src/core/types/giftTiersTypes";
import { CheckOutGiftStep, CheckOutStep } from "src/core/types";
import {
  Container,
  Title,
  TitleWrapper,
  Wrapper,
} from "src/components/molecules/GiftPayment/styledComponents";
import { InfoComponent } from "src/components/molecules/GiftPayment/components/InfoComponent";
import { RadioType } from "src/core/types/radioTypes";
import { PaymentTypeComponent } from "src/components/molecules/GiftPayment/components/PaymentTypeComponent";
import { PaymentConfirmation } from "src/components/molecules/RecapPayment/components/PaymentConfirmation";

import cardIcon from "../../../assets/credit_card_cervena.svg";
import { useTranslation } from "react-i18next";
import { usePostGiftReservation } from "src/core/queries/usePostGiftReservation";
import { SubPageHeader } from "src/components/molecules/SubPageHeader";
import { setGiftStep } from "src/core/utils/setGiftStep";
import { useNavigate } from "react-router-dom";
import { setNextGifStep } from "src/core/utils/setNextGifStep";

export const GiftPayment = ({
  step,
  cardsAmount,
  selectedCard,
  paymentType,
  setPaymentType,
  updateCheckOutState,
  panels,
}: {
  selectedCard: TGiftCardTier;
  cardsAmount: number;
  step: CheckOutStep;
  paymentType: RadioType;
  setPaymentType: React.Dispatch<SetStateAction<RadioType>>;
  panels: CheckOutGiftStep[];
  updateCheckOutState: (newPanelsState: CheckOutGiftStep[]) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token") || "";

  const { mutate, status } = usePostGiftReservation();

  const goToNextStep = () => {
    const { newPanels } = setNextGifStep({
      currentKey: +step.key,
      panels: panels,
    });
    updateCheckOutState(newPanels);
  };

  const invokeConfirmation = () => {
    const mutateData: GiftPurchase = {
      gift_card_type: selectedCard.id,
      pos: "kustod",
      amount: cardsAmount,
      payment_method: paymentType,
      token: token,
    };
    mutate(mutateData);
    goToNextStep();
  };

  const setPrevStep = () => {
    const { newPanels } = setGiftStep({
      currentKey: 1,
      panels: panels,
    });
    updateCheckOutState(newPanels);
  };

  return step.isEnabled ? (
    <Container>
      <SubPageHeader navigateFunc={setPrevStep} />
      <Wrapper>
        <TitleWrapper>
          <img src={cardIcon} alt="cardIcon" />
          <Title>{t("recap_and_payment")}</Title>
        </TitleWrapper>
        <InfoComponent cardsAmount={cardsAmount} selectedCard={selectedCard} />
        <PaymentTypeComponent radioState={paymentType} setRadioState={setPaymentType} />
        <PaymentConfirmation onConfirm={invokeConfirmation} />
      </Wrapper>
    </Container>
  ) : (
    <div></div>
  );
};

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 690px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  margin: 20px auto 0 auto;
  border: 1px solid #b9b9b9;
  padding: 18px 34px;
  background: #ffffff;

  @media (min-width: 801px) {
    max-width: 100%;
  }
`;

import React, { useEffect, useState } from "react";
import {
  ConfirmButton,
  ReceiptWrapper,
  TicketsButton,
  TicketsButtonDesktop,
  TicketsWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "src/components/molecules/PaymentConfirmation/styledComponents";
import { ReceiptComponent } from "src/components/molecules/RecapPayment/components/ReceiptComponent";
import { CheckOutStep } from "core/types";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import { useNavigate } from "react-router-dom";
import { TICKET_TYPE_ROUTE } from "src/core/routing";
import { CircularProgress } from "@mui/material";

import checkMark from "../../../assets/check (1).svg";
import checkMarkButton from "../../../assets/checkmark.svg";
import printIcon from "../../../assets/printing.svg";
import SVGTicket from "src/components/atoms/SVGTicket";
import { useGetSVGTicket } from "src/core/queries/useGetSVGTicket";

interface PaymentConfirmationProps {
  step: CheckOutStep;
}

export const PaymentConfirmation = ({ step }: PaymentConfirmationProps) => {
  const { t } = useTranslation();
  const [isTablet, setIsTablet] = useState<boolean>(window.innerWidth <= 801);
  const navigate = useNavigate();
  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID) || "";

  const {
    data: svgTicket,
    status: svgStatus,
    refetch,
  } = useGetSVGTicket({ reservation_id: reservationId });

  const updateMedia = () => {
    setIsTablet(window.innerWidth <= 801);
  };

  const showTickets = () => {
    void refetch();
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return step.isEnabled ? (
    <Wrapper>
      <TitleWrapper>
        <img src={checkMark} alt="checkMark" />
        <Title>{t("confirmation")}</Title>
      </TitleWrapper>
      <ReceiptWrapper>
        <ReceiptComponent originalSize={true} />
        {!isTablet && (
          <TicketsButtonDesktop onClick={showTickets}>
            {t("show_tickets_desktop")}
            <img src={printIcon} alt="printIcon" />
          </TicketsButtonDesktop>
        )}
      </ReceiptWrapper>
      <ConfirmButton isActive={true} onClick={() => navigate(TICKET_TYPE_ROUTE)}>
        {t("finish")}
        <img src={checkMarkButton} alt="checkMark" />
      </ConfirmButton>
      {isTablet && <TicketsButton onClick={showTickets}>{t("show_tickets")}</TicketsButton>}
      <TicketsWrapper>
        {svgStatus === "loading" && <CircularProgress color={"error"} />}
        {svgTicket && svgStatus === "success" && <SVGTicket element={svgTicket} />}
      </TicketsWrapper>
    </Wrapper>
  ) : (
    <div></div>
  );
};

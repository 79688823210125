import { IGroupTours } from "src/core/types/groupTours";
import { TicketFilterPayload } from "src/core/types/reduxTypes";

export const filterTimeSlots = ({
  slots,
  filter,
}: {
  slots: IGroupTours[];
  filter: TicketFilterPayload;
}) => {
  switch (filter) {
    case "evening":
      return slots.filter((slot: IGroupTours) => parseInt(slot.time.slice(0, 2)) >= 12);
    case "morning":
      return slots.filter((slot: IGroupTours) => {
        const hour = parseInt(slot.time.slice(0, 2));
        const minute = parseInt(slot.time.slice(3, 5));
        return hour < 12 || (hour === 12 && minute === 0);
      });
    default:
      return slots;
  }
};

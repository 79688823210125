import axios from "axios";
import { useMutation } from "react-query";
import { UPDATE_GIFT_TOUR } from "src/core/endpoints";
import { GroupContextType } from "src/core/context/groupContext";

export const usePostTicket = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation(
    (submitData: GroupContextType) => {
      return axios.post(`${window.BASE_API_URL}/${UPDATE_GIFT_TOUR}`, submitData);
    },
    {
      onSuccess: onSuccess,
    },
  );
};

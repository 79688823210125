import React from "react";

import {
  CanBeUsedDesc,
  ContentWrapper,
  Dot,
  HorizontalLine,
  ListItem,
  ListItemWrapper,
  ListWrapper,
  Price,
  PriceDesc,
  PriceWrapper,
  Wrapper,
} from "src/components/pages/PageGifts/components/TicketSelectComponent/styledComponents";
import { TicketComponent } from "src/components/pages/PageGifts/components/TicketComponent";
import { Loc } from "src/core/types/loc";
import { TGiftCardTier } from "core/types/giftTiersTypes";
import { useTranslation } from "react-i18next";

export const TicketSelectComponent = ({
  loc,
  cards,
  onCardSelect,
  selectedCard,
}: {
  loc: Loc;
  cards: TGiftCardTier[];
  onCardSelect: (card: TGiftCardTier) => void;
  selectedCard: TGiftCardTier | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {cards &&
        cards.map((card) => (
          <TicketComponent
            key={card.id}
            card={card}
            title={card.name[loc]}
            price={card.monetary_value}
            onCardSelect={onCardSelect}
            selectedCard={selectedCard}
          />
        ))}
      {selectedCard && (
        <ContentWrapper>
          <CanBeUsedDesc>{t("can_be_used_for")}</CanBeUsedDesc>
          <ListItemWrapper>
            {selectedCard.description[loc].map((description) => (
              <ListWrapper key={description}>
                <Dot />
                <ListItem>{description}</ListItem>
              </ListWrapper>
            ))}
          </ListItemWrapper>
          <HorizontalLine />
          <PriceWrapper>
            <PriceDesc>Cena</PriceDesc>
            <Price>{`${selectedCard.monetary_value} ${t("cz_currency")}`}</Price>
          </PriceWrapper>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

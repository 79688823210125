import React from "react";
import {
  Count,
  CounterText,
  CountWrapper,
  Price,
  PriceText,
  PriceWrapper,
  VerticalLine,
  Wrapper,
} from "src/components/molecules/GiftReservationConfirm/components/Receipt/styledComponents";
import { useTranslation } from "react-i18next";
import { TGiftCardTier } from "core/types/giftTiersTypes";

export const Receipt = ({
  amount,
  total,
  selectedCard,
}: {
  amount: number;
  total: number;
  selectedCard: TGiftCardTier;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <CountWrapper>
        <CounterText>{`${t("total_cards")} ${selectedCard.name.cs}`}</CounterText>
        <Count>{amount}</Count>
      </CountWrapper>
      <VerticalLine />
      <PriceWrapper>
        <PriceText>{t("final_price")}</PriceText>
        <Price>{`${total} ${t("cz_currency")}`}</Price>
      </PriceWrapper>
    </Wrapper>
  );
};

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 690px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  margin: 20px auto 0 auto;
  border: 1px solid #b9b9b9;
  padding: 18px 34px;
  background: #ffffff;

  @media (min-width: 801px) {
    max-width: 100%;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 50px;
`;

export const CheckBoxLabel = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

import React from "react";

import {
  CenteredRow,
  DefaultRow,
  Wrapper,
} from "src/components/pages/PageGroups/components/TableHeader/styledComponents";
import { useTranslation } from "react-i18next";

export const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <DefaultRow>{t("row_date")}</DefaultRow>
      <DefaultRow>{t("row_time")}</DefaultRow>
      <CenteredRow>{t("row_desc")}</CenteredRow>
      <DefaultRow>{t("row_status")}</DefaultRow>
      <DefaultRow>{t("row_payment")}</DefaultRow>
    </Wrapper>
  );
};

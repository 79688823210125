import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoBlockWrapper = styled.div`
  width: 100%;
  max-width: 690px;
  height: 121px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #b9b9b9;
  background: #ffffff;
`;

export const InfoBlockItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardDescription = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const CardDescriptionPrice = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border-bottom: 2px dotted #b9b9b9;
`;

export const TotalPriceDescription = styled.span`
  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const TotalPrice = styled.span`
  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

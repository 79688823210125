import React from "react";
import {
  CardDescription,
  CardDescriptionPrice,
  HorizontalLine,
  InfoBlockItem,
  InfoBlockWrapper,
  TotalPrice,
  TotalPriceDescription,
  Wrapper,
} from "src/components/molecules/GiftPayment/components/InfoComponent/styledComponents";
import { TGiftCardTier } from "src/core/types/giftTiersTypes";
import { useTranslation } from "react-i18next";

export const InfoComponent = ({
  cardsAmount,
  selectedCard,
}: {
  cardsAmount: number;
  selectedCard: TGiftCardTier;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <InfoBlockWrapper>
        <InfoBlockItem>
          <CardDescription>{`${cardsAmount}x Poukaz`}</CardDescription>
          <CardDescriptionPrice>{selectedCard.monetary_value * cardsAmount}</CardDescriptionPrice>
        </InfoBlockItem>
        <HorizontalLine />
        <InfoBlockItem>
          <TotalPriceDescription>{t("final_price")}</TotalPriceDescription>
          <TotalPrice>{`${selectedCard.monetary_value * cardsAmount} ${t(
            "cz_currency",
          )}`}</TotalPrice>
        </InfoBlockItem>
      </InfoBlockWrapper>
    </Wrapper>
  );
};

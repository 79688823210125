import styled from "styled-components";

interface IPayedRow {
  isPayed: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  height: 64px;
  display: grid;
  padding: 12px 16px;
  grid-template-columns: 60px 30px 254px 100px 55px 1fr;
  place-items: center;
  column-gap: 25px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  div:last-child {
    justify-self: end;
  }

  &:hover {
    border: 1px solid red;
  }
`;

export const Note = styled.div`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #062762;
`;

export const PayedRow = styled.div<IPayedRow>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${(props) => (props.isPayed ? "#29CC6A" : "red")};
`;

export const BoldText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

export const PaymentMethod = styled.div`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

export const CheckButton = styled.div`
  width: 76px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 25px;
  border: 1px solid red;
  color: red;
  cursor: pointer;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  &:hover {
    background-color: red;
    color: #ffffff;
  }
`;

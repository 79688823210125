import React, { useEffect, useState } from "react";
import { Wrapper, InsideWrapper } from "src/components/pages/PageSetTypeOfTicket/styledComponents";
import PanelRow from "src/components/pages/PageSetTypeOfTicket/components/PanelRow";
import { useGetBundles } from "src/core/queries/useGetBundles";
import { CircularProgress } from "@mui/material";
import { Loc } from "src/core/types/loc";
import { IBundle } from "src/core/types/bundles";
import { SubPageHeader } from "src/components/molecules/SubPageHeader";
import { useNavigate } from "react-router-dom";
import { TICKET_TYPE_ROUTE } from "src/core/routing";
import { useTranslation } from "react-i18next";
import HomeHeader from "src/components/molecules/Header";
import Footer from "src/components/molecules/Footer";

const PageSetTypeOfTicket = () => {
  const { t } = useTranslation();
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const navigate = useNavigate();
  const { data, status } = useGetBundles();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };

  const backToMainPage = () => {
    navigate(TICKET_TYPE_ROUTE);
  };

  const { singleBundles, multipleBundles } = (data || []).reduce<{
    singleBundles: IBundle[];
    multipleBundles: IBundle[];
  }>(
    (acc, bundle) => {
      if (bundle.is_single) {
        acc.singleBundles.push(bundle);
      } else {
        acc.multipleBundles.push(bundle);
      }
      return acc;
    },
    { singleBundles: [], multipleBundles: [] },
  );

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <>
      <Wrapper>
        <HomeHeader />
        <SubPageHeader navigateFunc={backToMainPage} />
        <InsideWrapper>
          {data && status === "success" && (
            <>
              <PanelRow
                title={!isMobile ? t("choose_bundle") : ""}
                panels={multipleBundles.sort((a, b) => a.position - b.position)}
                loc={loc}
              />
              <PanelRow
                title={!isMobile ? t("choose_bundle_alternative") : ""}
                panels={singleBundles.sort((a, b) => a.position - b.position)}
                loc={loc}
              />
            </>
          )}
          {status === "loading" && <CircularProgress color={"error"} />}
        </InsideWrapper>
      </Wrapper>
      {!isMobile && <Footer />}
    </>
  );
};

export default PageSetTypeOfTicket;

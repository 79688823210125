import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid #b9b9b9;
`;

export const CanBeUsedDesc = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 30px;
  color: #000;
`;

export const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000000;
  flex-shrink: 0;
`;

export const ListItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

export const ListItem = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border-bottom: 2px dotted #b9b9b9;
`;

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PriceDesc = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

export const Price = styled.span`
  font-family: Montserrat, serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.76px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

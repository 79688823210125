import React, { useEffect, useState } from "react";
import {
  Container,
  FormWrapper,
  InnerTitle,
  PaymentInfo,
  Price,
  RadioWrapper,
  TextFieldWrapper,
  Title,
  Wrapper,
} from "src/components/pages/PageGroupTicket/styledComponents";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PaymentConfirmation } from "src/components/molecules/RecapPayment/components/PaymentConfirmation";
import { useGetGroupTours } from "src/core/queries/useGetGroupTours";
import { useNavigate, useParams } from "react-router-dom";
import { IGroupTours } from "src/core/types/groupTours";
import { GroupPaymentMethod } from "core/types/groupTypes";
import { RadioType } from "src/core/types/radioTypes";
import { RadioComponent } from "src/components/molecules/RecapPayment/components/RadioComponent";
import { groupPaymentTypes } from "src/core/lists/paymentTypes";
import { usePostTicket } from "src/core/queries/usePostTicket";
import { GroupContextType } from "core/context/groupContext";
import { GROUPS_ROUTE } from "src/core/routing";
import ticket_icon from "../../../assets/ticket_color.svg";
import person_icon from "../../../assets/kontaktni_udaje_cervene.svg";
import { SubPageHeader } from "src/components/molecules/SubPageHeader";

export const PageGroupTicket = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [radioState, setRadioState] = useState<RadioType<GroupPaymentMethod>>("cash");

  const onSuccessUpdate = () => {
    navigate(GROUPS_ROUTE);
  };

  const { data } = useGetGroupTours();
  const { mutate } = usePostTicket({ onSuccess: onSuccessUpdate });

  const ticketsForm = useForm({
    defaultValues: {
      id: 0,
      name: "",
      visitor_amount: 0,
      price: 0,
      payment_method: radioState,
    },
    reValidateMode: "onChange",
    mode: "all",
  });

  const { control, getValues, reset, setValue } = ticketsForm;

  const invokeConfirmation = () => {
    const ticketData = getValues();
    if (!ticketData.name || !ticketData.price || !ticketData.visitor_amount) return;
    const preparedDataToUpdate: GroupContextType = {
      id: id ? +id : 0,
      name: ticketData.name,
      visitor_amount: ticketData.visitor_amount,
      price: ticketData.price,
      payment_method: radioState as GroupPaymentMethod,
    };
    mutate(preparedDataToUpdate);
  };

  const getTicketById = (): IGroupTours | undefined => {
    if (!data || !id) return;
    return data.find((ticket) => ticket.id === +id);
  };

  const handleNavigateToGroups = () => {
    navigate(GROUPS_ROUTE);
  };

  useEffect(() => {
    if (data) {
      const ticket = getTicketById();
      if (!ticket) return;
      setValue("name", ticket.name);
      setValue("visitor_amount", ticket.visitor_amount);
      setValue("price", ticket.price);
      setValue("payment_method", ticket.payment_method);
      setRadioState(ticket.payment_method);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <Container>
      <SubPageHeader navigateFunc={handleNavigateToGroups} />
      <Title>
        <img src={ticket_icon} alt="ticket_icon" />
        {t("group_visit")}
      </Title>
      <Wrapper>
        <InnerTitle>
          <img src={person_icon} alt="person_icon" />
          {t("contact_information")}
        </InnerTitle>
        <FormWrapper>
          <FormControl>
            <InputLabel
              htmlFor="name"
              sx={{
                color: "black",
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.Mui-focused": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.Mui-error": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#E31340",
                },
              }}
            >
              {t("name")}
            </InputLabel>
            <Controller
              name="name"
              control={control}
              rules={{
                validate: (v) => {
                  return /^[A-Za-z\sěščřžýá0-9]+$/.test(v) || t("fill_in_name");
                },
                required: t("fill_in_name"),
              }}
              render={({ field, fieldState: { isTouched, error } }) => (
                <TextFieldWrapper>
                  <OutlinedInput
                    id="name"
                    inputProps={{ maxLength: 50 }}
                    value={field.value}
                    onChange={field.onChange}
                    error={!!error}
                    label={t("fill_in_name")}
                    sx={{
                      "& input": {
                        width: "260px",
                        color: error ? "#E31340" : "#000000",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#79747E",
                        legend: {
                          width: "50px",
                        },
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#062762",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #000000",
                        outline: "none",
                        boxShadow: "none",
                      },
                    }}
                  />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </TextFieldWrapper>
              )}
            />
          </FormControl>
          <FormControl>
            <InputLabel
              htmlFor="visitor_amount"
              sx={{
                color: "black",
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.Mui-focused": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.Mui-error": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#E31340",
                },
              }}
            >
              {t("amount")}
            </InputLabel>
            <Controller
              name="visitor_amount"
              control={control}
              rules={{
                validate: (v) => {
                  return /^[1-9][0-9]*$/.test(String(v)) || t("fill_in_amount");
                },
                required: t("fill_in_amount"),
              }}
              render={({ field, fieldState: { isTouched, error } }) => (
                <TextFieldWrapper>
                  <OutlinedInput
                    id="visitor_amount"
                    inputProps={{ maxLength: 50 }}
                    value={field.value}
                    onChange={field.onChange}
                    error={!!error}
                    label={t("fill_in_amount")}
                    sx={{
                      color: "black",
                      "& input": {
                        width: "260px",
                        color: error ? "#E31340" : "#000000",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#79747E",
                        legend: {
                          width: "50px",
                        },
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#062762",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #000000",
                        outline: "none",
                        boxShadow: "none",
                      },
                    }}
                  />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </TextFieldWrapper>
              )}
            />
          </FormControl>
          <FormControl>
            <InputLabel
              htmlFor="price"
              sx={{
                color: "black",
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.MuiFormLabel-colorPrimary.MuiFormLabel-filled:hover": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.Mui-focused": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#000000",
                },
                "&.Mui-error": {
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#E31340",
                },
              }}
            >
              {t("total_to_pay")}
            </InputLabel>
            <Controller
              name="price"
              control={control}
              rules={{
                validate: (v) => {
                  return /^[1-9][0-9]*$/.test(String(v)) || t("fill_in_price");
                },
                required: t("fill_in_price"),
              }}
              render={({ field, fieldState: { isTouched, error } }) => (
                <TextFieldWrapper>
                  <OutlinedInput
                    id="price"
                    inputProps={{ maxLength: 50 }}
                    value={field.value}
                    onChange={field.onChange}
                    error={!!error}
                    label={t("fill_in_price")}
                    sx={{
                      "& input": {
                        width: "260px",
                        color: error ? "#E31340" : "#000000",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#79747E",
                        legend: {
                          width: "120px",
                        },
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "#E31340" : "#062762",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #000000",
                        outline: "none",
                        boxShadow: "none",
                      },
                    }}
                  />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </TextFieldWrapper>
              )}
            />
          </FormControl>
          <PaymentInfo isPayed={getTicketById()?.paid || false}>
            {getTicketById()?.paid ? t("group_paid") : t("group_not_paid")}
          </PaymentInfo>
        </FormWrapper>
        <RadioWrapper>
          <RadioComponent
            options={groupPaymentTypes}
            radioState={radioState}
            setRadioState={setRadioState}
          />
        </RadioWrapper>
        <Price>{`${getTicketById()?.price || 0},- ${t("cz_currency")}`}</Price>
        <PaymentConfirmation
          onConfirm={invokeConfirmation}
          confirmButtonText={t("save")}
          withArrow={false}
        />
      </Wrapper>
    </Container>
  );
};

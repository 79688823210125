import styled from "styled-components";

interface IConfirmButton {
  isActive: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 690px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 20px;
  background: #f8f8f8;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const ConfirmButton = styled.div<IConfirmButton>`
  width: 100%;
  max-width: 355px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  gap: 20px;
  color: #ffffff;
  background: ${(props) => (props.isActive ? "#e31340" : "#e1e1e1")};
  cursor: pointer;

  @media (min-width: 801px) {
    margin-left: 68%;
  }
`;

import React, { forwardRef, useEffect, useState } from "react";

import { CheckOutGiftStep, CheckOutStep } from "src/core/types";
import { TicketSelector } from "src/components/molecules/TicketSelector";
import { Wrapper } from "src/components/organisms/CheckOutGift/styledComponents";
import { Loc } from "src/core/types/loc";
import { TGiftCardTier } from "src/core/types/giftTiersTypes";
import { useGetGiftTiers } from "src/core/queries/useGetGiftTiers";
import { GiftPayment } from "src/components/molecules/GiftPayment";
import HomeHeader from "src/components/molecules/Header";
import { GiftReservationConfirm } from "src/components/molecules/GiftReservationConfirm";

type PaymentType = "card" | "cash";

export interface ICheckOutState {
  panels: CheckOutGiftStep[];
}

const CheckOutGift = forwardRef<HTMLDivElement>(() => {
  const loc: Loc = (localStorage.getItem("loc") as Loc) || "cs";
  const [selectedCard, setSelectedCard] = useState<TGiftCardTier>();
  const [cardsAmount, setCardsAmount] = useState<number>(1);
  const [paymentType, setPaymentType] = useState<PaymentType>("card");

  const [checkOutState, setCheckOutState] = useState<ICheckOutState>({
    panels: [
      {
        isEnabled: true,
        key: "1",
        component: TicketSelector,
      },
      {
        isEnabled: false,
        key: "2",
        component: GiftPayment,
      },
      {
        isEnabled: false,
        key: "3",
        component: GiftReservationConfirm,
      },
    ],
  });
  //queries
  const { data, status } = useGetGiftTiers();

  const selectCard = (card: TGiftCardTier) => {
    setSelectedCard(card);
  };

  const handleIncrement = () => {
    setCardsAmount((prev) => prev + 1);
  };

  const handleDecrement = () => {
    if (cardsAmount < 2) return;
    setCardsAmount((prev) => prev - 1);
  };

  const updateCheckOutState = (newPanelsState: CheckOutStep[]) => {
    setCheckOutState((prevState) => {
      return {
        ...prevState,
        panels: newPanelsState,
      };
    });
  };

  useEffect(() => {
    if (data) {
      const basicCard = data.find((card) => card.id === "standard_plus");
      if (basicCard) {
        setSelectedCard(basicCard);
      }
    }
  }, [data]);

  useEffect(() => {
    setCardsAmount(1);
  }, [selectedCard]);

  return (
    <>
      <HomeHeader />
      <Wrapper>
        {checkOutState?.panels?.map((step) => {
          const Component = step.component;
          return (
            <Component
              key={step.key}
              step={step}
              panels={checkOutState.panels}
              updateCheckOutState={updateCheckOutState}
              checkOutState={checkOutState}
              status={status}
              cards={data}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              cardsAmount={cardsAmount}
              selectedCard={selectedCard}
              selectCard={selectCard}
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement}
              loc={loc}
            />
          );
        })}
      </Wrapper>
    </>
  );
});
CheckOutGift.displayName = "CheckOutGift";
export default CheckOutGift;

import React, { useEffect, useState } from "react";
import {
  ContentWrapper,
  DateWrapper,
  Discount,
  HorizontalLine,
  Item,
  LeftContent,
  PriceWrapper,
  ReceiptWrapper,
  RightContent,
  Text,
  TicketPrice,
  TicketsCount,
  TotalPrice,
  TotalPriceItem,
  TotalPriceText,
  TourPricesWrapper,
  VerticalLine,
} from "src/components/molecules/RecapPayment/components/ReceiptComponent/styledComponents";
import { TourName } from "src/core/types";
import { ticketTypes } from "src/core/lists/ticketTypes";
import { ITour, SlotKey } from "src/core/types/bundles";
import { getFormattedDateToText } from "src/core/helpers/getFormattedDateToText";
import { getDiscountPrice } from "src/core/utils/getDiscountPrice";
import { useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import { useTranslation } from "react-i18next";
import { Loc } from "src/core/types/loc";

export const ReceiptComponent = ({ originalSize }: { originalSize?: boolean }) => {
  const { t } = useTranslation();
  const selectedTickets = useSelector((state: RootState) => state.reservationData?.selectedTickets);
  const globTickets = useSelector((state: RootState) => state.reservationData?.tickets);
  const discount = useSelector((state: RootState) => state.discount);
  const selectedSlots = useSelector((state: RootState) => state.timeSlot);
  const selectedTours = useSelector((state: RootState) => state.selectedTours);
  const selectedBundle = useSelector((state: RootState) => state.bundle);

  const loc = (sessionStorage.getItem("loc") as Loc) || "cs";

  const [tickets, setTickets] = useState<Record<TourName, number>>({
    adult: 0,
    baby: 0,
    family: 0,
    child: 0,
    senior: 0,
    free: 0,
  });

  const getTicketsCount = () => {
    let total = 0;
    if (selectedTickets) {
      (Object.keys(selectedTickets) as SlotKey[]).forEach((ticket) => {
        selectedTickets[ticket].forEach((_ticket) => (total += _ticket.amount));
      });
    }
    return total;
  };

  const getTourPrice = (tour: SlotKey): number => {
    let total = 0;
    if (selectedTickets) {
      selectedTickets[tour].forEach((_ticket) => {
        const selectedTicket = selectedTickets[tour].find((ticket) => ticket.id === _ticket.id);
        const searchedTicket = globTickets?.find(
          (globTicket) => globTicket.id === selectedTicket?.id,
        );
        if (searchedTicket) {
          total += _ticket.amount * +searchedTicket.price;
        }
      });
    }
    return total;
  };

  const getDiscountType = (onlyType?: boolean) => {
    if (onlyType) {
      if (discount && discount.discount_type === "percent") return "%";
      if (discount && discount.discount_type === "flat") return `${t("cz_currency")}`;
    }
    if (discount) {
      if (discount.discount_type === "percent") {
        return `${discount.amount} %`;
      }
      if (discount.discount_type === "flat") {
        return `${discount.amount} ${t("cz_currency")}`;
      }
    }
    return selectedBundle?.discount ? "%" : "";
  };

  const getTotalPrice = () => {
    let total = 0;
    selectedBundle.tours.forEach((tour: ITour) => (total += getTourPrice(tour.name)));
    sessionStorage.setItem(
      "totalPrice",
      String(getDiscountPrice({ price: total, percentage: selectedBundle?.applied_discount })),
    );
    return total;
  };

  const getTicketTypesPrice = (tourTicket: TourName): string => {
    let total = 0;
    if (!selectedTickets) return `0 ${t("cz_currency")}`;
    const tours = (Object.keys(selectedTickets) as SlotKey[]) || [];
    const searchedTickets = tours.map((tour) =>
      selectedTickets[tour].find((_ticket) => _ticket.name === tourTicket),
    );

    if (searchedTickets) {
      searchedTickets.forEach((ticket) => {
        total += getDiscountPrice({
          price: (ticket?.price || 0) * (ticket?.amount || 0),
          percentage: selectedBundle?.discount,
        });
      });
      return `${total} ${t("cz_currency")}`;
    }
    return `0 ${t("cz_currency")}`;
  };

  const getTourName = (tour: SlotKey): string => {
    if (selectedTours) {
      return selectedTours[tour].title[loc];
    }
    return "";
  };

  const getPriceWithDiscount = () => {
    if (discount) {
      if (discount.discount_type === "percent") {
        return getDiscountPrice({ price: getTotalPrice(), percentage: discount.amount });
      }
      if (discount.discount_type === "flat") {
        return (
          getDiscountPrice({ price: getTotalPrice(), percentage: selectedBundle?.discount || 0 }) -
          (discount.amount || 0)
        );
      }
    }
    return getDiscountPrice({ price: getTotalPrice(), percentage: selectedBundle?.discount || 0 });
  };

  useEffect(() => {
    const initialTickets = { adult: 0, child: 0, family: 0, senior: 0, baby: 0, free: 0 };
    if (selectedTickets) {
      const flatTickets = (Object.keys(selectedTickets) as SlotKey[]).flatMap(
        (ticket) => selectedTickets[ticket],
      );
      flatTickets.forEach((_ticket) => {
        initialTickets[_ticket.name] += _ticket.amount;
      });
    }
    setTickets(initialTickets);
  }, [selectedTickets]);
  return (
    <ContentWrapper originalSize={originalSize}>
      <LeftContent>
        <PriceWrapper>
          <Item>
            <Text>{t("total_tickets")}</Text>
            <TicketsCount>{getTicketsCount()}</TicketsCount>
          </Item>
        </PriceWrapper>

        {(Object.keys(tickets) as TourName[]).map((ticket) => {
          if (tickets[ticket] === 0) {
            return null;
          }
          return (
            <Item key={ticket}>
              <Text>{`${tickets[ticket]} x ${t(ticketTypes[ticket])}`}</Text>
              <TicketPrice>{getTicketTypesPrice(ticket)}</TicketPrice>
            </Item>
          );
        })}
        {discount && discount?.amount && (
          <Item>
            <Text>{`1 x ${discount.label}`}</Text>
            <TicketPrice>{`- ${getDiscountType()}`}</TicketPrice>
          </Item>
        )}

        <HorizontalLine />
        <TourPricesWrapper>
          {(Object.keys(selectedSlots) as SlotKey[]).map((slot: SlotKey) => (
            <Item key={slot}>
              <Item key={slot}>{getTourName(slot)}</Item>
              <TicketPrice>{`${getTourPrice(slot)} ${t("cz_currency")}`}</TicketPrice>
            </Item>
          ))}
        </TourPricesWrapper>
      </LeftContent>
      <VerticalLine />
      <RightContent>
        {selectedSlots &&
          (Object.keys(selectedSlots) as SlotKey[]).map((slot) => (
            <DateWrapper key={slot}>
              <Item justify={"flex-start"}>
                <Text>{t("time")}</Text>
                <TicketsCount>{selectedSlots[slot].time.slice(0, 5)}</TicketsCount>
              </Item>
              <Item justify={"flex-start"}>
                <Text>{t("date")}</Text>
                <TicketsCount>{getFormattedDateToText(new Date(), "DD.MM.YYYY")}</TicketsCount>
              </Item>
            </DateWrapper>
          ))}
        <ReceiptWrapper>
          <TotalPriceItem>
            <TotalPriceText>{t("total_price")}</TotalPriceText>
            <TotalPrice>{`${getTotalPrice()} ${t("cz_currency")}`}</TotalPrice>
          </TotalPriceItem>
          {(selectedBundle.discount || (discount && discount.amount)) && (
            <>
              <TotalPriceItem>
                <Discount>{t("sale")}</Discount>
                <Discount>{`${discount?.amount || selectedBundle?.discount || ""} ${getDiscountType(
                  true,
                )}`}</Discount>
              </TotalPriceItem>
              <TotalPriceItem>
                <TotalPriceText>{t("total_price")}</TotalPriceText>
                <TotalPriceText>{`${getPriceWithDiscount()} ${t("cz_currency")}`}</TotalPriceText>
              </TotalPriceItem>
            </>
          )}
        </ReceiptWrapper>
      </RightContent>
    </ContentWrapper>
  );
};

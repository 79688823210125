import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TicketTypeContent } from "src/components/atoms/TicketTypeContent";
import HomeHeader from "src/components/molecules/Header";
import style from "./styles.module.css";
import Footer from "src/components/molecules/Footer";

export const PageTicketType = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <>
      <Box>
        <div className={style.header}>{isMobile ? null : <HomeHeader />}</div>
        <TicketTypeContent />
      </Box>
      <Footer />
    </>
  );
};

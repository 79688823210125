import React, { useState } from "react";

import {
  Container,
  ContainerHeaderWrapper,
  HeaderWrapper,
  TableWrapper,
  Wrapper,
} from "src/components/pages/PageGroups/styledComponents";
import PreferButtons from "src/components/molecules/PreferButtons";
import { TicketFilterPayload } from "src/core/types/reduxTypes";
import { TableHeader } from "src/components/pages/PageGroups/components/TableHeader";
import { TicketTable } from "src/components/pages/PageGroups/components/TicketTable";
import { useGetGroupTours } from "src/core/queries/useGetGroupTours";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import ticket_icon from "../../../assets/ticket_color.svg";
import { filterTimeSlots } from "src/core/utils/filterTickets";
import { SubPageHeader } from "src/components/molecules/SubPageHeader";
import { useNavigate } from "react-router-dom";
import { TICKET_TYPE_ROUTE } from "src/core/routing";

export const PageGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [timeFilter, setTimeFilter] = useState<TicketFilterPayload>("everytime");

  const { data, status } = useGetGroupTours();

  const handleNavigateToMainPage = () => {
    navigate(TICKET_TYPE_ROUTE);
  };

  return (
    <Container>
      <SubPageHeader navigateFunc={handleNavigateToMainPage} />
      <ContainerHeaderWrapper>
        <HeaderWrapper>
          <img src={ticket_icon} alt="ticket_icon" />
          {t("group_visit")}
        </HeaderWrapper>
        <PreferButtons selectedOption={timeFilter} setSelectedOption={setTimeFilter} />
      </ContainerHeaderWrapper>
      <Wrapper>
        <TableWrapper>
          <TableHeader />
          {data && status === "success" && (
            <TicketTable tickets={filterTimeSlots({ slots: data, filter: timeFilter })} />
          )}
          {(!data || status !== "success") && <CircularProgress color={"error"} />}
        </TableWrapper>
      </Wrapper>
    </Container>
  );
};

import { useQuery } from "react-query";
import axios from "axios";
import { GIFT_TIERS } from "../endpoints";
import { TGiftCardTier } from "core/types/giftTiersTypes";

export const useGetGiftTiers = () => {
  const fetchGiftTiers: () => Promise<TGiftCardTier[]> = async () => {
    const res = await axios.get(`${window.BASE_API_URL}/${GIFT_TIERS}`);
    return res.data.tiers;
  };

  return useQuery(`gift-tiers`, fetchGiftTiers, {
    enabled: true,
    refetchOnWindowFocus: false,
  });
};

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 34px;
  border: 2px solid #b9b9b9;
  border-radius: 10px;
  background: #ffffff;
`;

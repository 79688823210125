import styled from "styled-components";

interface IItem {
  justify?: string;
}

interface IContentWrapper {
  originalSize?: boolean;
}

export const ContentWrapper = styled.div<IContentWrapper>`
  display: flex;
  align-items: stretch;
  min-height: 208px;
  max-width: 690px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 0 auto;
  border: 1px solid #b9b9b9;
  padding: 18px 34px;
  background: #ffffff;

  @media (min-width: 801px) {
    max-width: ${(props) => (props.originalSize ? "690px" : "100%")};
  }
`;

export const LeftContent = styled.div`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: 801px) {
    max-width: 100%;
  }
`;

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const TicketPrice = styled.div`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-wrap: nowrap;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #062762;
`;

export const Item = styled.div<IItem>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.justify || "space-between"};
  align-items: center;
  margin: 5px 0;
  gap: 8px;
`;

export const Text = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const TicketsCount = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #e31340;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 15px 0;
  border-bottom: 1px dotted #b9b9b9;
`;

export const TourPricesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 0 15px;
  border-left: 1px dotted #b9b9b9;
`;

export const RightContent = styled.div`
  max-width: 228px;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: 801px) {
    max-width: 100%;
  }
`;

export const DateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ReceiptWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const TotalPriceItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TotalPriceText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const TotalPrice = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const Discount = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #e31340;
`;

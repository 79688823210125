import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 801px) {
    margin-top: 130px;
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 740px;
  display: grid;
  padding: 12px 16px;
  grid-template-columns: 60px 30px 254px 100px 55px;
  column-gap: 25px;
`;

export const DefaultRow = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

export const CenteredRow = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
`;

import React from "react";
import {
  BoldText,
  CheckButton,
  Note,
  PayedRow,
  PaymentMethod,
  Wrapper,
} from "src/components/pages/PageGroups/components/TicketRow/styledComponents";
import { IGroupTours } from "src/core/types/groupTours";
import { getFormattedDateToText } from "src/core/utils/getFormattedDateToText";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const TicketRow = ({ ticket }: { ticket: IGroupTours }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/groups/${ticket.id}`);
  };
  return (
    <Wrapper>
      <BoldText>{getFormattedDateToText(ticket.date)}</BoldText>
      <BoldText>{ticket.time}</BoldText>
      <Note>{ticket.note}</Note>
      <PayedRow isPayed={ticket.paid}>{ticket.paid ? t("paid") : t("unpaid")}</PayedRow>
      <PaymentMethod>{t(`group_${ticket.payment_method}`)}</PaymentMethod>
      <CheckButton onClick={handleNavigate}>{t("choose")}</CheckButton>
    </Wrapper>
  );
};

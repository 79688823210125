import React from "react";
import { Wrapper } from "src/components/pages/PageGroups/components/TicketTable/styledComponents";
import { TicketRow } from "src/components/pages/PageGroups/components/TicketRow";
import { IGroupTours } from "core/types/groupTours";

export const TicketTable = ({ tickets }: { tickets: IGroupTours[] }) => {
  return (
    <Wrapper>
      {tickets.map((ticket) => (
        <TicketRow key={ticket.id} ticket={ticket} />
      ))}
    </Wrapper>
  );
};

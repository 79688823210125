import React from "react";
import { Wrapper } from "src/components/atoms/NextStepButton/styledComponents";
import { ButtonArrow } from "src/components/icons/ButtonArrow";

const NextStepButton = ({
  text,
  handler,
  isActive = true,
  withArrow = true,
}: {
  text: string;
  handler: () => void;
  isActive?: boolean;
  withArrow?: boolean;
}) => {
  return (
    <Wrapper onClick={handler} isActive={isActive} withArrow={withArrow}>
      {text} {withArrow && <ButtonArrow />}
    </Wrapper>
  );
};

export default NextStepButton;

import { RadioType } from "src/core/types/radioTypes";

export const paymentTypes: { id: RadioType; label: string }[] = [
  {
    id: "card",
    label: "card",
  },
];

export const paymentGiftTypes: { id: RadioType; label: string }[] = [
  {
    id: "card",
    label: "card",
  },
  {
    id: "cash",
    label: "cash",
  },
];

export const groupPaymentTypes: { id: RadioType; label: string }[] = [
  {
    id: "cash",
    label: "cash",
  },
  {
    id: "invoice",
    label: "invoice",
  },
];

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBack, Text, Wrapper } from "src/components/molecules/SubPageHeader/styledComponents";

import arrow from "../../../assets/arrow.svg";

export const SubPageHeader = ({ navigateFunc }: { navigateFunc: () => void }) => {
  const { t } = useTranslation();
  const [isTablet, setIsTablet] = useState<boolean>(window.innerWidth <= 801);

  const updateMedia = () => {
    setIsTablet(window.innerWidth <= 801);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return isTablet ? (
    <Wrapper>
      <ButtonBack onClick={navigateFunc}>
        <img src={arrow} alt="arrow" />
        <Text>{t("back")}</Text>
      </ButtonBack>
    </Wrapper>
  ) : (
    <div></div>
  );
};

import React from "react";

import {
  TicketDescription,
  TicketPrice,
  Wrapper,
} from "src/components/pages/PageGifts/components/TicketComponent/styledComponents";
import { useTranslation } from "react-i18next";
import { TGiftCardTier } from "core/types/giftTiersTypes";

export const TicketComponent = ({
  title,
  price,
  card,
  onCardSelect,
  selectedCard,
}: {
  title: string;
  price: number;
  card: TGiftCardTier;
  onCardSelect: (card: TGiftCardTier) => void;
  selectedCard: TGiftCardTier | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper
      isActive={!!selectedCard && selectedCard.id === card.id}
      onClick={() => onCardSelect(card)}
    >
      <TicketDescription>{title}</TicketDescription>
      <TicketPrice>{`${price} ${t("cz_currency")}`}</TicketPrice>
    </Wrapper>
  );
};

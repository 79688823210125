import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import {
  BUNDLE_ROUTE,
  FINANTIAL_CLOSURE_ROUTE,
  PRE_RESERVATION_ROUTE,
  TICKET_TYPE_ROUTE,
  GIFT_ROUTE,
  GROUPS_ROUTE,
} from "src/core/routing";
import { PagePurchase } from "../PagePurchase";
import { PageTicketType } from "../PageTicketType";
import React from "react";
import { PageFinantialClosure } from "../PageFinantialClosure";
import PageSetTypeOfTicket from "src/components/pages/PageSetTypeOfTicket";
import { PageGifts } from "src/components/pages/PageGifts";
import { PageGroups } from "src/components/pages/PageGroups";
import { PageGroupTicket } from "src/components/pages/PageGroupTicket";

export const MainPage: React.FC = () => {
  return (
    <Box>
      <Routes>
        <Route path={TICKET_TYPE_ROUTE} element={<PageTicketType />} />
        <Route path={GIFT_ROUTE} element={<PageGifts />} />
        <Route path={BUNDLE_ROUTE} element={<PageSetTypeOfTicket />} />
        <Route path={PRE_RESERVATION_ROUTE} element={<PagePurchase />} />
        <Route path={FINANTIAL_CLOSURE_ROUTE} element={<PageFinantialClosure />} />
        <Route path={GROUPS_ROUTE} element={<PageGroups />} />
        <Route path={`${GROUPS_ROUTE}/:id`} element={<PageGroupTicket />} />
      </Routes>
    </Box>
  );
};

import axios from "axios";
import { useMutation } from "react-query";
import { GIFT_PURCHASE } from "src/core/endpoints";
import { GiftPurchase } from "src/core/types/giftTiersTypes";

export const usePostGiftReservation = () => {
  return useMutation((submitData: GiftPurchase) => {
    return axios.post(`${window.BASE_API_URL}/${GIFT_PURCHASE}`, submitData);
  });
};
